import supportedCurrency from 'src/assets/emoney/tokens/currencies';
import { chainSortOrder } from 'src/utils/emoney/chains';
import { currencySortOrder, get as sharedGet } from 'src/utils/emoney/tokens';
import type { ChainName } from 'types/emoney/Chain';
import type { CurrencyCode } from 'types/emoney/Token';

export const get = sharedGet;

/**
 * returns a sorted array of contract tokens
 * @param {Array} tokens array of contract tokens
 */

export const sortByTokens = <
  T extends { currency?: CurrencyCode; chain?: ChainName },
>(
  myObject: T[],
): T[] => {
  return [...myObject].sort((a, b) => {
    if (!a.currency || !a.chain) return 1;
    if (!b.currency || !b.chain) return -1;
    if (
      !chainSortOrder.includes(a.chain) ||
      !currencySortOrder.includes(a.currency)
    )
      return 1;
    if (
      !chainSortOrder.includes(b.chain) ||
      !currencySortOrder.includes(b.currency)
    )
      return -1;
    const aIndex = currencySortOrder.indexOf(a.currency);
    const bIndex = currencySortOrder.indexOf(b.currency);
    const aChainIndex = chainSortOrder.indexOf(a.chain);
    const bChainIndex = chainSortOrder.indexOf(b.chain);
    if (aIndex === bIndex) {
      return aChainIndex < bChainIndex ? -1 : 1;
    }
    return aIndex < bIndex ? -1 : 1;
  });
};

const defaultCurrency: Uppercase<CurrencyCode> = 'EUR';
const options = (sc = supportedCurrency()) =>
  sc.map((k) => ({
    value: k.code?.toUpperCase(),
    label: `${k.code?.toUpperCase()} / ${k.name}`,
  }));

/**
 * Currency helper functions - Get default currency by code
 * @param {Array} sc supported currencies
 * @returns {Array} supported currencies, filtered by currency code.
 */
const getDefault = (sc = supportedCurrency()) => get(defaultCurrency, sc);

const getOrDefault = (
  code: Uppercase<CurrencyCode> | Lowercase<CurrencyCode> | undefined,
  sc = supportedCurrency(),
) => get(code, sc) ?? getDefault();

const formatter = (
  code: Uppercase<CurrencyCode> | Lowercase<CurrencyCode> | undefined,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
) => {
  const cu = getOrDefault(code);
  return new Intl.NumberFormat(cu?.format, {
    minimumFractionDigits,
    maximumFractionDigits,
  });
};

export default {
  supportedCurrency,
  formatter,
  getDefault,
  getOrDefault,
  get,
  options,
  defaultCurrency,
  sortByTokens,
};
